@use "../base/mixins" as *;

.cards-widget {
  display: grid;
  grid-template: auto / repeat(auto-fill, minmax(320px, 1fr));
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: min(100%, 710px);
  margin: 0 auto;

  @include max(phone-landscape) {
    grid-template-columns: repeat(1, 1fr);
  }
}
