@use "base/mixins" as *;

.button {
  display: grid;
  place-content: center;
  letter-spacing: .5px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  appearance: none;
  border: none;
  margin: 0;
  width: fit-content;
  height: 56px;
  border-radius: 1px;
  padding: 0 46px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 18px;
  color: var(--c-white);
  background-size: 200% 100%;
  transition: all 525ms ease;
  z-index: 1;

  @include min(tablet-landscape) {
    font-size: 20px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-position: 100% 100%;
    }
  }

  &--vivid-red {
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: -1;
    }

    &:before {
      background-color: var(--c-vivid-red);
    }

    @media (hover: hover) and (pointer: fine) {
      transition: 275ms ease-in;

      &:after {
        width: calc(100% + 56px);
        border-right: 56px solid transparent;
        border-bottom: 56px solid var(--c-white);
        transform: translateX(-100%);
        transition: 325ms ease-in-out;
      }

      &:hover {
        color: var(--c-vivid-red);

        &:after {
          transform: translateX(0);
        }
      }
    }
  }

  &--sky-blue {
    background-color: transparent;
    color: var(--c-white);
    box-shadow: 0 0 0 2px inset var(--c-white);
    transition: 325ms ease-in-out;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--c-white);
        color: var(--c-black);
      }
    }
  }
}
