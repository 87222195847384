@use "base/mixins" as *;

.widget-front-banner {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100vw;
  display: grid;
  justify-items: center;

  @include min(tablet-portrait) {
    max-width: unset;
    overflow: unset;
  }

  &__text {
    position: relative;
    z-index: 1;
    display: grid;
    justify-items: center;
    width: min(100% - 30px, 1200px);

    @include min(tablet-portrait) {
      margin: -100px auto auto;
    }
  }

  .headline-small {
    margin: var(--v-offset-sm) 0 0;
    width: unset;
  }
}
